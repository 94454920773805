import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  linearProgressClasses,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import React, { useRef, useState } from "react";
import { solicitorForm, vendorForm } from "../../utils/validators";
import { useFormik } from "formik";
import { getJobsData, updateVendorsForm } from "../../features/jobs/jobs";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "../../components/forms/SelectInput";
import { Textarea } from "@material-tailwind/react";
import { getAuthData, uploadFile } from "../../features/auth/authSlice";
import ClearIcon from "@mui/icons-material/Clear";
import { AppType, getFileSize } from "../../utils/helper";
import Alert from "../../utils/alert";
import styled from "@emotion/styled";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

function ViewVendor({ data, closeModal, refresh }) {
  const dispatch = useDispatch(),
    { vendorFormPayload, modalLoading } = useSelector(getJobsData),
    { loading, appType } = useSelector(getAuthData),
    [loader, setLoader] = useState({
      report: false,
      photos: false,
      fileName: "",
      fileSize: "",
    });

  const {
    values,
    setValues,
    handleBlur,
    handleChange,
    handleFocus,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    validationSchema: AppType.cs === appType ? solicitorForm : vendorForm,
    initialValues: vendorFormPayload,
    onSubmit: async (values) => {
      let payload = {};
      Object.keys(values)?.forEach(
        (key) =>
          (payload[key] =
            typeof values[key] === "string" ? values[key]?.trim() : values[key])
      );

      payload.accountId = data?.AcctRefID;
      if (values?.landmark === "Yes") payload.landmark = true;
      else {
        payload.landmark = false;
        payload.photos = [];
      }
      if (AppType.cs === appType) payload.addressDescription = "none";

      const res = await dispatch(
        updateVendorsForm({
          ...payload,
          status: payload?.status?.toUpperCase(),
        })
      ).unwrap();
      res?.success && refresh?.();
    },
  });

  const uploadMore = useRef();
  const config = {
    onUploadProgress: (e) => {},
    headers: { "Content-Type": "multipart/form-data" },
  };

  const handleFileUpload = async (e) => {
    const formData = new FormData();
    if (e.target.files.length) {
      for (let file of e.target.files) {
        if (file.size > 6000000) {
          Alert({
            type: "error",
            message: "File size cannot be more than 6MB",
          });
          return;
        }
        formData.append("files[]", file);
      }

      let res;
      if (e.target.name === "photos") {
        setLoader({ ...loader, photos: true });
        res = await dispatch(uploadFile({ formData, config })).unwrap();
        setLoader({ ...loader, photos: false });
      } else {
        setLoader({ ...loader, report: true });
        res = await dispatch(uploadFile({ formData, config })).unwrap();
        setLoader({
          ...loader,
          report: false,
          fileName: e.target.files[0].name,
          fileSize: getFileSize({ size: e.target.files[0].size }),
        });
      }

      if (res?.success) {
        if (e.target.name === "photos")
          setValues({ ...values, photos: [...values.photos, ...res?.data] });
        else {
          setValues({ ...values, solicitorReport: res?.data?.[0] });
        }
      }
    }
  };

  const removeImg = (idx) => {
    let handleFilter = (img, i) => i !== idx;
    setValues({ ...values, photos: values?.photos?.filter(handleFilter) });
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));

  return (
    <Box component="form" noValidate onSubmit={handleSubmit}>
      <div className="flex justify-between w-full">
        <p className="font-[600]">Account Name</p>
        <p>{data?.AcctName}</p>
      </div>

      <br />
      {AppType.cav === appType ? (
        <>
          <div className="flex justify-between w-full">
            <p className="font-[600]">Customer Address</p>
            <p>{data?.AcctAddress || "N/A"}</p>
          </div>
          {data?.UpdatedAcctAddress && (
            <div className="flex justify-between w-full">
              <br />
              <p className="font-[600]">Updated Address</p>
              <p>{data?.UpdatedAcctAddress || "N/A"}</p>
            </div>
          )}

          <br />
          <div>
            <label htmlFor="addressDescription" className="font-[600]">
              Address Description
            </label>

            <TextField
              value={values.addressDescription}
              type="text"
              name="addressDescription"
              id="addressDescription"
              className="w-full border outline-none border-[#CBD5E1] placeholder:text-[#CBD5E1] bg-[#F8FAFC] p-0"
              placeholder="14 oshinowo street"
              required
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              error={errors.addressDescription && touched.addressDescription}
              helperText={
                errors.addressDescription && touched.addressDescription
                  ? errors.addressDescription
                  : ""
              }
            />
          </div>

          <br />
          <div>
            <label htmlFor="landmark" className="font-[600]">
              Proof of Residence
            </label>

            <SelectInput
              {...{
                className:
                  "w-full border-none outline-none border-[#CBD5E1] placeholder:text-[#CBD5E1] bg-[#F8FAFC] rounded",
                value: values.landmark,
                name: "landmark",
                handleChange,
                handleBlur,
                handleFocus,
                searchLoading: false,
                loading: false,
                error: errors.landmark && touched.landmark,
                helperText: errors.landmark,
                options: ["Yes", "No"],
              }}
            />
          </div>

          {values.landmark === "Yes" && (
            <>
              <br />
              <input
                type="file"
                onChange={handleFileUpload}
                className="absolute w-0 cursor-pointer opacity-0 border"
                id="uploadPicture"
                name="photos"
                multiple
              />
              <div>
                <Typography className="font-[600]">
                  Capture Residence
                </Typography>
                <label
                  htmlFor="uploadPicture"
                  className="text-[#D70900] cursor-pointer"
                >
                  <span>
                    <PhotoCameraIcon color="red" /> Upload Picture{" "}
                  </span>{" "}
                </label>
                <small className="text-[12px] text-[#a5240afa]">
                  - Maximum of 6MB
                </small>
              </div>

              <div className="flex flex-wrap">
                {values?.photos?.map((url, i) => {
                  return (
                    <div className="relative mr-4 mt-4" key={i}>
                      <ClearIcon
                        style={{ top: "-10px", right: "-10px" }}
                        onClick={(_) => removeImg(i)}
                        className="cursor-pointer text-[#000] p-1 z-30 shadow-xl bg-[#eee] rounded-full absolute hover:text-red-500 transition-all hover:rotate-90 rotate-0"
                      />
                      <img
                        key={i}
                        alt={i}
                        src={url}
                        style={{
                          width: "109px",
                          height: "109px",
                          borderRadius: "5px",
                        }}
                      />
                    </div>
                  );
                })}
                {(loading || values?.photos?.length > 0) && (
                  <label
                    htmlFor="uploadPicture"
                    className="w-[109px] block cursor-pointer mt-4 border"
                  >
                    <div
                      onMouseLeave={
                        uploadMore.current
                          ? () => {
                              if (uploadMore.current) {
                                uploadMore.current.style.transform = "scale(1)";
                                uploadMore.current.style.transition =
                                  "all .150s";
                              }
                            }
                          : () => {}
                      }
                      onMouseEnter={
                        uploadMore.current
                          ? () => {
                              if (uploadMore.current) {
                                uploadMore.current.style.transform =
                                  "scale(1.4)";
                                uploadMore.current.style.transition =
                                  "all .150s";
                              }
                            }
                          : () => {}
                      }
                      className="w-[109px] inline-flex h-[109px]  rounded  justify-center items-center relative transition duration-150 ease-in-out"
                    >
                      {loader.photos ? (
                        <CircularProgress variant="indeterminate" size="2em" />
                      ) : (
                        <PhotoCameraIcon ref={uploadMore} />
                      )}
                    </div>
                  </label>
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <div className="flex justify-between w-full">
          <p className="font-[600]">Customer RC Number</p>
          <p>{data?.RCNumber || "N/A"}</p>
        </div>
      )}

      {AppType.cs === appType && (
        <div>
          <br />
          <Typography className="font-[600]">Solicitor's Report</Typography>
          <label
            htmlFor="solicitorReport"
            className="text-[#D70900] cursor-pointer"
          >
            <input
              type="file"
              id="solicitorReport"
              onChange={handleFileUpload}
              name="solicitorReport"
              className="hidden"
            />
            {!loader.fileName ? (
              <>
                <span>
                  <CloudUploadIcon color="error" /> Upload Solicitor's Report{" "}
                </span>
                <small className="text-[12px] text-[#a5240afa]">
                  - Maximum of 6MB
                </small>
              </>
            ) : (
              <>
                {loader.fileName} -{" "}
                <small className="text-[#a5240afa]">{loader.fileSize}</small>{" "}
              </>
            )}
          </label>
          <div className="mt-1">
            {loader.report && loading && (
              <BorderLinearProgress
                variant="indeterminate"
                color="primary"
                value={50}
              />
            )}
          </div>
          <FormHelperText
            variant="standard"
            error={errors.solicitorReport && touched.solicitorReport}
            className="py-1 bg-[#F8FAFC] text-[0.75rem] my-0 px-[14px] leading-[1.66] font-normal"
          >
            {errors.solicitorReport}
          </FormHelperText>
        </div>
      )}

      <br />
      <label htmlFor="comment" className="font-[600]">
        Solicitor’s Comment
      </label>
      <FormControl className="m-0 p-0">
        <Textarea
          name="comment"
          id="comment"
          value={values?.comment}
          {...{
            onChange: handleChange,
            onBlur: handleBlur,
            onFocus: handleFocus,
          }}
          rows="3"
          type="text"
          className="w-full border border-non outline-none  resize-none  border-[#CBD5E1] placeholder:text-[#CBD5E1] p-0 m-0 rounded"
          placeholder="Placeholder"
          error={errors.comment && touched.comment}
          color={errors.comment && touched.comment && "red"}
          variant="outlined"
          cols={100}
          style={{ backgroundColor: "#F8FAFC", mb: 0 }}
          size="lg"
        />
        {errors.comment && touched.comment && (
          <FormHelperText
            variant="standard"
            error={errors.comment && touched.comment}
            className="py-1 bg-[#F8FAFC] text-[0.75rem] my-0 px-[14px] leading-[1.66] font-normal"
          >
            {errors.comment}
          </FormHelperText>
        )}
      </FormControl>

      <br />
      <br />
      <FormControl
        component={"fieldset"}
        className="w-full"
        required
        error={errors.membershipType}
        variant="standard"
      >
        <label htmlFor="status" className="block font-[600]">
          Job Status
        </label>
        <RadioGroup
          aria-labelledby="Status"
          value={values.status}
          name="status"
        >
          <FormControlLabel
            value="Successful"
            onChange={handleChange}
            control={<Radio id="status" color="error" />}
            label="Successful"
          />
          <FormControlLabel
            value="Unsuccessful"
            onChange={handleChange}
            control={<Radio color="error" id="status" />}
            label="Unsuccessful"
          />
        </RadioGroup>
        {errors.status && (
          <FormHelperText
            className="w-full bg-[#f8fafc] pt-1 text-[0.75rem] px-[14px] leading-[1.66] font-normal"
            error={errors.status ? true : false}
          >
            {errors.status}
          </FormHelperText>
        )}
      </FormControl>

      <div className="flex justify-end capitalize">
        <div className="inline-block">
          <Button
            onClick={closeModal}
            variant="contained"
            color="error"
            className="capitalize mr-5"
            style={{ textTransform: "capitalize", margin: "2em" }}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            {...{ loading: modalLoading }}
            variant="text"
            sx={{
              height: "2.3rem",
              alignItems: "center",
              px: "1em",
              fontSize: "semibold",
              color: "red",
              background: "#f5ebeb",
              textTransform: "capitalize",
            }}
          >
            {" "}
            Submit{" "}
          </LoadingButton>
        </div>
      </div>
    </Box>
  );
}

export default ViewVendor;
