/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react";
import HomeLayout from "../../layouts/Home";
import TableLayout from "../../components/table/CustomTable";
import SelectButton from "../../components/others/SelectBtn";
import PrintButton from "../../components/others/Print";
import { Typography } from "@material-tailwind/react";
import { ICopy } from "../../utils/icons.utils";
import DateRangePicker from "../../components/others/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchData,
  getDownloadData,
  getJobsData,
  setPagination,
} from "../../features/jobs/jobs";
import { useLocation } from "react-router-dom";
import {
  fetchStates,
  getDefaultState,
  setPrintMod,
} from "../../features/defaultSlice";
import {
  AppType,
  Roles,
  camelCasedPathName,
  capitalize,
  getRole,
  handleCopy,
  jobsQueues,
} from "../../utils/helper";
import dayjs from "dayjs";
import { fetchVendors, getVendorsData } from "../../features/vendor/vendor";
import { getAuthData } from "../../features/auth/authSlice";

const Reports = () => {
  const tableRef = useRef();
  const dispatch = useDispatch(),
    { currentJob, modal, loading, pagination, downloadData, ...jobsData } =
      useSelector(getJobsData),
    { states } = useSelector(getDefaultState),
    { vendors } = useSelector(getVendorsData),
    { pathname } = useLocation(),
    { printMod } = useSelector(getDefaultState),
    { appType } = useSelector(getAuthData),
    reports = jobsData[camelCasedPathName()],
    [filter, setFilter] = useState({
      queue: "",
      vendor: "",
      state: "",
      status: "",
    });

  const stateOption = useMemo(() => {
    const result = states?.map((state) => ({
      name: state?.Name,
      url: "#",
      id: state?.Id,
    }));
    filter.state && result.unshift({ name: "All", id: "", url: "#" });

    return result;
  }, [states.length, filter.state]);

  const vendorAssigned = useMemo(() => {
    const result = vendors
      ?.map?.((d) => ({
        name: d?.VendorPreferredName || d?.VendorName,
        url: "#",
        id: d?.VendorID,
      }))
      ?.filter?.(({ name = false }) => Boolean(name));
    filter.vendor && result.unshift({ name: "All", id: "", url: "#" });

    return result;
  }, [vendors.length, filter.vendor]);

  const statusOption = useMemo(() => {
    const result = [
      { name: "Successful", url: "#", id: 1 },
      { name: "Unsuccessful", url: "#", id: 2 },
      { name: "Vague", url: "#", id: 3 },
    ];
    filter.status && result.unshift({ name: "All", id: "", url: "#" });

    return result;
  }, [filter.status]);

  const queues = useMemo(() => {
    const result = jobsQueues.slice(); // copies all the array items into a new array
    filter.queue && result.unshift({ name: "All", id: "", url: "#" });
    return result;
  }, [filter.queue]);

  useEffect(() => {
    dispatch(setPrintMod({ pageTitle: "Reports" }));
    dispatch(
      setPagination({
        page: 1,
        status: "",
        vendorId: "",
        stateId: "",
        startDate: "",
        endDate: "",
        search: "",
      })
    );
    Promise.all([dispatch(fetchStates()), dispatch(fetchVendors())]);
  }, []);

  const handleSearch = ({ target: { value } }) => {
    dispatch(setPagination({ ...pagination, search: value }));
  };

  const handleDateFilter = (date) => {
    dispatch(
      setPagination({ startDate: date?.startDate, endDate: date?.endDate })
    );
  };

  useEffect(() => {
    dispatch(
      getDownloadData({
        url: pathname,
        type: appType === AppType.cs ? "corporate/" : "",
      })
    );
    let cb = () => {};
    if (pagination?.search) {
      dispatch(setPagination({ page: 1 }));
      cb = setTimeout((_) => (async () => await dispatch(fetchData()))(), 700);
    } else cb = (async () => await dispatch(fetchData()))();

    return () => {
      clearTimeout(cb);
    };
  }, [
    pagination.pageSize,
    pagination.search,
    pagination.queue,
    pagination.page,
    pagination.status,
    pagination.vendorId,
    pagination.stateId,
    pagination.endDate,
    pagination.startDate,
  ]);

  let data = reports?.map((d) => {
    let model = {};

    if (AppType.cs === appType) {
      model = {
        "CS ID": d?.AcctRefID,
        "Account Number": d?.AcctNumber || "N/A",
        "Account Name": capitalize(d?.AcctName) || "N/A",
        "RC Number": d?.RCNumber || "N/A",
        "Date of Incorporation": d?.DateOfIncorporation
          ? dayjs(d?.DateOfIncorporation).format("MMM DD, YYYY")
          : "N/A",
        State: capitalize(d?.State?.Name) || "N/A",
        "Solicitor's Name":
          capitalize(
            d?.AssignedVendor?.VendorPreferredName ||
              d?.AssignedVendor?.VendorName ||
              d?.VendorJobs?.[0]?.Vendor?.VendorPreferredName ||
              d?.VendorJobs?.[0]?.Vendor?.VendorName
          ) || "N/A",
        ...(getRole() !== Roles.vendor && {
          "Assigned By": d?.InitiatedByUser?.firstName
            ? `${d?.InitiatedByUser?.firstName} ${d?.InitiatedByUser?.lastName}`
            : "N/A",
        }),
        "Completed Date": d?.DateConfirmed
          ? dayjs(d?.DateConfirmed).format("MMM DD, YYYY")
          : "N/A",
        Status: d?.AddedInfo || "N/A",
      };
    } else {
      model = {
        "CAV ID": d?.AcctRefID,
        "Account Number": d?.AcctNumber || "N/A",
        "Account Name": capitalize(d?.AcctName) || "N/A",
        Address: capitalize(d?.AcctAddress) || "N/A",
        State: capitalize(d?.State?.Name) || "N/A",
        "Solicitor's Name":
          capitalize(
            d?.AssignedVendor?.VendorPreferredName ||
              d?.AssignedVendor?.VendorName ||
              d?.VendorJobs?.[0]?.Vendor?.VendorPreferredName ||
              d?.VendorJobs?.[0]?.Vendor?.VendorName
          ) || "N/A",
        ...(getRole() !== Roles.vendor && {
          "Assigned By": d?.InitiatedByUser?.firstName
            ? `${d?.InitiatedByUser?.firstName} ${d?.InitiatedByUser?.lastName}`
            : "N/A",
        }),
        "Assigned Date": d?.DateAssigned
          ? dayjs(d?.DateAssigned).format("DD/MM/YY, hh:mm A")
          : "N/A",
        "Completed Date": d?.DateConfirmed
          ? dayjs(d?.DateConfirmed).format("MMM DD, YYYY")
          : "N/A",
        Status: d?.AddedInfo || "N/A",
      };
    }

    model._data = d;
    return model;
  });

  return (
    <HomeLayout {...{ handleSearch, value: pagination.search }}>
      <div className="flex items-end flex-wrap gap-2 justify-between">
        <div className="">
          <Typography className="text-[24px] font-[500]">Report</Typography>
          <Typography className="text-[18px] font-[400]">
            All Reports
          </Typography>
        </div>
        <div className="flex flex-wrap md:justify-end gap-4 md:mt-0 my-3">
          <button
            onClick={(_) => handleCopy(data)}
            className="h-[2.3rem] flex  items-center rounded-md px-3 border-2 border-[#E6E6E6] text-sm font-semibold text-dark_red"
          >
            <img src={ICopy} alt="ICopy" />
            Copy
          </button>
          <PrintButton
            {...{
              componentRef: tableRef,
              documentTitle: printMod.pageTitle,
            }}
          />
          <SelectButton
            btTextClass="bg-dark_red text-white hover:bg-light_red"
            options={downloadData}
            title="Download"
          />
        </div>
      </div>
      <div className="flex flex-wrap md:justify-end gap-4 md:mt-0 mt-3">
        <DateRangePicker {...{ handleDateFilter }} />
        <SelectButton
          btTextClass="bg-white text-dark_red border border-[#F8F7F7]"
          options={queues}
          title={filter.queue || "Queue"}
          onClick={(queue) => {
            setFilter({
              ...filter,
              queue: queue?.id === "" ? "" : queue?.name,
            });
            dispatch(setPagination({ queue: queue?.id }));
          }}
          download={false}
        />
        <SelectButton
          btTextClass="bg-white text-dark_red border border-[#F8F7F7]"
          options={vendorAssigned}
          title={filter.vendor || "Solicitor Assigned"}
          onClick={(vendor) => {
            setFilter({
              ...filter,
              vendor: vendor?.id === "" ? "" : vendor?.name,
            });
            dispatch(setPagination({ vendorId: vendor?.id }));
          }}
          download={false}
        />
        <SelectButton
          btTextClass="bg-white text-dark_red border border-[#F8F7F7]"
          options={stateOption}
          title={filter.state || "State"}
          download={false}
          onClick={(state) => {
            setFilter({
              ...filter,
              state: state?.id === "" ? "" : state?.name,
            });
            dispatch(setPagination({ stateId: state?.id }));
          }}
        />
        <SelectButton
          btTextClass="bg-white text-dark_red border border-[#F8F7F7]"
          options={statusOption}
          title={filter.status || "Status"}
          download={false}
          onClick={(status) => {
            setFilter({
              ...filter,
              status: status?.id === "" ? "" : status?.name,
            });
            dispatch(
              setPagination({
                status: status?.id ? status?.name?.toUpperCase() : "",
              })
            );
          }}
        />
      </div>
      <TableLayout
        {...{
          printMod: { ...printMod, ref: tableRef },
          pagination,
          setPagination,
          isLoading: loading,
          data,
          tableMsg: ["Reports", ""],
        }}
      />
    </HomeLayout>
  );
};

export default Reports;
