import { Box } from "@mui/material";
import React from "react";

function ViewVendor({ data }) {
  return (
    <Box sx={{ mb: "1.5em" }}>
      <div className="flex justify-between w-full">
        <p className="font-[600]">Solicitor Name</p>
        <p>{data?.VendorName || "N/A"}</p>
      </div>

      <br />
      <div className="flex justify-between w-full">
        <p className="font-[600]">Preferred Name</p>
        <p>{data?.VendorPreferredName || "N/A"}</p>
      </div>

      <br />
      <div className="flex justify-between w-full">
        <p className="font-[600]">First Name</p>
        <p>{data?.User?.firstName || "N/A"}</p>
      </div>
      <br />
      <div className="flex justify-between w-full">
        <p className="font-[600]">Last Name</p>
        <p>{data?.User?.lastName || "N/A"}</p>
      </div>

      <br />
      <div className="flex justify-between w-full">
        <p className="font-[600]">Email Address</p>
        <p>{data?.VendorEmail || "N/A"}</p>
      </div>

      {/* <br />
      <div className="flex justify-between w-full">
        <p className="font-[600]">Account Number</p>
        <p>{data?.VendorAcctNo || "N/A"}</p>
      </div> */}

      <br />
      <div className="flex justify-between flex-wrap w-full">
        <p className="font-[600]">State Coverage</p>
        <div className="flex flex-wrap" style={{ textTransform: "capitalize" }}>
          {data?.MapStateVendors?.map((vendor, idx) => {
            const notLast = idx + 1 !== data?.MapStateVendors?.length;
            const secondToLast = idx + 1 === data?.MapStateVendors?.length - 1;
            return (
              <div>
                <span
                  className={`${
                    secondToLast ? "ml- inline-block" : ""
                  } capitalize`}
                >
                  {vendor?.State?.Name}
                </span>
                <span
                  className={`${
                    secondToLast ? "mx-2 inline-block" : "mr-2"
                  } lowercase`}
                >
                  {secondToLast ? "and" : notLast ? "," : ""}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </Box>
  );
}

export default ViewVendor;
