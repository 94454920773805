import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Avatar } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { IDivider } from "../../utils/icons.utils";
import { useSelector } from "react-redux";
import { getAuthData } from "../../features/auth/authSlice";
import { Roles, getRole } from "../../utils/helper";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavMenu({ user, role, handleLogout }) {
  const { appType } = useSelector(getAuthData);

  let userName =
    getRole() === Roles.vendor
      ? user?.Solicitors?.[0]?.VendorPreferredName ||
        user?.Vendors?.[0]?.VendorName
      : `${user?.firstName || "Leo"} ${user?.lastName || "Virtual"}`;
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-dark_red text-white px-3 py-2 text-sm font-semibold shadow-s hover:shadow-lg items-center">
          <span className="md:text-[12px] text-[10px] capitalize">
            {appType || "CAV"}
          </span>{" "}
          <img src={IDivider} alt="IDivider" className="mx-3" />
          <span className="flex items-center cursor-pointer">
            <Avatar
              src={user?.profileImage}
              sx={{ width: "30px", height: "30px" }}
              className="border "
            />
          </span>
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-white"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-4 py-3 flex items-center">
            <Avatar src={user?.profileImage} className="border mr-2" />
            <span>
              <p className="text-md mb-0 pb-0 capitalize">{userName}</p>
              {/* <p className="truncate capitalize text-sm mt-0 pt-0 font-medium text-gray-500">
                {role}
              </p> */}
            </span>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={handleLogout}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "group flex w-full px-4 py-2 text-left text-sm"
                  )}
                >
                  <LogoutIcon
                    sx={{ width: "18px" }}
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Log out
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
