/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingButton } from "@mui/lab";
import { Box, Button } from "@mui/material";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getJobsData } from "../../features/jobs/jobs";
import { useFormik } from "formik";
import { vRejectAndReassign } from "../../utils/validators";
import SelectInput from "../../components/forms/SelectInput";
import { useEffect } from "react";
import {
  fetchVendorsByStateId,
  getVendorsData,
  setPagination,
} from "../../features/vendor/vendor";
import Alert from "../../utils/alert";
import {
  getManualReassignmentData,
  manuallyReassignJob,
} from "../../features/jobs/manualReassignment";

function VendorAssignment({ data, closeModal, refresh, text }) {
  const dispatch = useDispatch(),
    { rejectAndReassignPayload } = useSelector(getJobsData),
    { vendors, pagination, loading } = useSelector(getVendorsData),
    { modalLoading } = useSelector(getManualReassignmentData),
    {
      values,
      handleBlur,
      handleChange,
      handleFocus,
      handleSubmit,
      errors,
      touched,
    } = useFormik({
      validationSchema: vRejectAndReassign,
      initialValues: rejectAndReassignPayload,
      onSubmit: async (values) => {
        const payload = {
          accountIds:
            data instanceof Array
              ? data?.map((d) => d?.AcctRefID)
              : [data?.AcctRefID],
          vendorId: values.newVendorId,
        };

        const res = await dispatch(manuallyReassignJob(payload)).unwrap();
        if (res?.success) {
          Alert({
            type: "success",
            message: `Job ${text ? "assigned" : "re-assigned"} successfully`,
          });
          closeModal?.();
          refresh?.();
        }
      },
    });

  useEffect(() => {
    let cb = () => {};
    if (data instanceof Array === false && data?.StateId === null) {
      Alert({
        timer: 10000,
        type: "error",
        message:
          "This Job is not associated to any state, therefore, no solicitor can be assigned to it.",
      });
    } else {
      let stateId;

      if (data instanceof Array) {
        stateId = data?.map((d) => d.StateId);

        if (stateId.filter((state) => !state).length) {
          Alert({
            timer: 10000,
            type: "error",
            message: `There seem to be a job without a state in your selection, please remove it and try again.`,
          });
          return;
        }
      } else stateId = data?.StateId;

      if (stateId) {
        if (pagination?.search) {
          dispatch(setPagination({ page: 1 }));
          cb = setTimeout(
            (_) =>
              (async () => await dispatch(fetchVendorsByStateId(stateId)))(),
            700
          );
        } else
          cb = (async () => await dispatch(fetchVendorsByStateId(stateId)))();
      } else {
        Alert({
          timer: 10000,
          type: "error",
          message:
            "One or more of the selected Jobs is not associated to a state, therefore, cannot be assigned to a vendor.",
        });
      }
    }
    return () => {
      clearTimeout(cb);
    };
  }, [pagination.search]);

  const options = useMemo(() => {
    // eslint-disable-next-line array-callback-return
    return vendors
      ?.map((vendor) => {
        if (
          data?.AssignedVendor?.VendorPreferredName !==
            vendor?.VendorPreferredName ||
          data?.AssignedVendor?.VendorName !== vendor?.VendorName
        ) {
          return {
            value:
              vendor?.VendorPreferredName ||
              vendor?.VendorName ||
              "Solicitor " + vendor?.VendorID,
            id: vendor?.VendorID,
          };
        }
      })
      ?.filter(Boolean);
  }, [loading]);

  const handleSearch = ({ target: { value } }) => {
    dispatch(setPagination({ ...pagination, search: value }));
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit}>
      <div>
        <label htmlFor="" className="font-[600]">
          To Solicitor
        </label>

        <SelectInput
          {...{
            className:
              "w-full border-none outline-none border-[#CBD5E1] placeholder:text-[#CBD5E1] bg-[#F8FAFC] rounded",
            value: values.newVendorId,
            name: "newVendorId",
            handleChange,
            handleBlur,
            handleFocus,
            searchValue: pagination.search,
            searchLoading: pagination.search && loading,
            loading,
            handleSearch,
            error: errors.newVendorId && touched.newVendorId,
            helperText: errors.newVendorId,
            options: options,
          }}
        />
      </div>

      <div className="flex justify-end capitalize">
        <div className="inline-block">
          <LoadingButton
            type="submit"
            variant="text"
            loading={modalLoading}
            sx={{
              height: "2.3rem",
              alignItems: "center",
              px: "1em",
              fontSize: "semibold",
              color: "red",
              background: "#f5ebeb",
              textTransform: "capitalize",
            }}
          >
            {text || "Reassign"}
          </LoadingButton>
          <Button
            onClick={closeModal}
            variant="contained"
            color="error"
            className="capitalize mr-5"
            style={{ textTransform: "capitalize", margin: "2em" }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Box>
  );
}

export default VendorAssignment;
