import { LoadingButton } from "@mui/lab";
import { Box, TextField, Typography } from "@mui/material";
import AES from "crypto-js/aes";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { changePassword, getAuthData } from "../../features/auth/authSlice";
import { getVendorsData } from "../../features/vendor/vendor";
import Alert from "../../utils/alert";
import config from "../../utils/config";
import { IBankUser } from "../../utils/icons.utils";
import { userResetPassword } from "../../utils/validators";

const ChangePassword = () => {
  const dispatch = useDispatch(),
    navigate = useNavigate(),
    { changePasswordPayload, loading } = useSelector(getVendorsData),
    { otp, payload } = useSelector(getAuthData),
    { state } = useLocation(),
    [passState, setPassState] = useState({ msg: "", value: "" });

  const formik = useFormik({
    initialValues: changePasswordPayload,
    validationSchema: userResetPassword,
    onSubmit: async ({ ...values }) => {
      try {
        values.verificationCode = otp;
        values.newPassword = AES.encrypt(
          values.password,
          config.encryptionKey
        ).toString();
        const action = changePassword;
        if (passState.value === values.password) {
          const { email, verificationCode, newPassword } = {
            ...values,
            email: payload?.email,
          };
          const res = await dispatch(
            action({ email, verificationCode, newPassword })
          ).unwrap();
          if (res?.success) {
            navigate("/");
          }
        } else if (!passState.value)
          setPassState({ ...passState, msg: "Confirm Password is required" });
      } catch (err) {
        console.error(err);
        Alert({ type: "error", message: "Something went wrong" });
      }
    },
  });

  return (
    <div className="px-5 py-5">
      <Box className="h-full ">
        <Box className="md:flex md:justify-between h-full">
          <div className="w-full  rounded-l-[20px] rounded-r-[20px] border-l md:border-r-0 border-r lg:p-16 md:p-8 ">
            <img alt="brand" src="/logo.svg" className="my-5 ml-[13%]" />
            <img
              src={IBankUser}
              alt="IBankUser"
              className="w-[73%] m-auto rounded-[24px]"
            />
          </div>
          <div className="flex flex-col justify-center w-full ">
            <div className="md:bg-[var(--home-bg)] xl:p-[15%] lg:p-14 md:p-5 w-full lg:py-[14%] md:py-16 h-full flex flex-col md:justify-center rounded-r-[20px]">
              <Box
                component="form"
                marginTop=""
                noValidate
                onSubmit={formik.handleSubmit}
                className="bg-white  lg:px-[10%] py-8 md:px-4  md:rounded-[24px]"
              >
                <h1 className="mb-5 text-[30px] font-bold">Change Password</h1>
                {state === null && (
                  <Typography className="text-[400] text-[16px] text-[#52525B]">
                    Change your password for security reason, once logged in
                  </Typography>
                )}

                <div className="mt-5">
                  <label
                    htmlFor="password"
                    className="block mb-4 text-[16px] font-medium  text-[#090914]"
                  >
                    New Password
                  </label>
                  <TextField
                    sx={{ ".MuiInputBase-root": { borderRadius: "10px" } }}
                    value={formik.values.password}
                    type="password"
                    name="password"
                    id="password"
                    className="block w-full bg-[#F8FAFC] border-0 py-4 pl-5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-3  sm:text-sm sm:leading-6"
                    placeholder="*****"
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onFocus={formik.handleFocus}
                    error={formik.errors.password && formik.touched.password}
                    helperText={
                      formik.errors.password && formik.touched.password
                        ? formik.errors.password
                        : ""
                    }
                  />
                </div>

                <div className="mt-5">
                  <label
                    htmlFor="password"
                    className="block mb-4 text-[16px] font-medium  text-[#090914]"
                  >
                    Confirm password
                  </label>

                  <TextField
                    sx={{ ".MuiInputBase-root": { borderRadius: "10px" } }}
                    value={passState.value}
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    className="block w-full bg-[#F8FAFC] border-0 py-4 pl-5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-3  sm:text-sm sm:leading-6"
                    placeholder="*****"
                    required
                    onChange={({ target: { value } }) => {
                      if (formik.values.password !== value)
                        setPassState({ value, msg: "Password mismatch" });
                      else setPassState({ value, msg: "" });
                    }}
                    error={passState.msg}
                    helperText={passState.msg}
                  />
                </div>

                <LoadingButton
                  variant="text"
                  color="error"
                  {...{
                    loading,
                    sx: {
                      textTransform: "capitalize",
                      mt: "2.5rem",
                      px: "2.5rem",
                      bgcolor: "#F8FAFC",
                      border: "1px solid rgba(233, 231, 231, 0.7)",
                      borderRadius: "10px",
                      py: "0.75rem",
                      fontSize: "18px",
                      "&:hover": { bgcolor: "#D70900" },
                    },
                  }}
                  type="submit"
                  className="rounded-[10px] md:py-3 py-3  font-semibold text-[#D70900]  hover:text-[#FFFFFF]"
                >
                  Submit
                </LoadingButton>
              </Box>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default ChangePassword;
