const environment = {};

const routes = {
  home: "/",
  login: "/",
  dashboard: "/dashboard",
  pendingJobs: "/pending-jobs",
  pendingAcknowledgment: "/pending-acknowledgement",
  rejectedJobs: "/rejected-jobs",
  closedJobs: "/closed-jobs",
  awaitingBranchConfirmation: "/awaiting-bank-confirmation",
  awaitingPayments: "/awaiting-payment",
  awaitingVendorAssignment: "/awaiting-vendor-assignment",
  paidJobs: "/paid-jobs",
  reports: "/reports",
  registeredEmployees: "/registered/employees",
  createEmployees: "/employees/create",
  manualReassignment: "/manual/reassignment",
  registeredVendor: "/vendor/registered",
  createVendor: "/vendor/create",
  changePassword: "/change/password",
  forgotPassword: "/forgot/password",
};

environment.development = {
  authProps: ["cav/token", "cav/user", "cav/app-type"],
  appType: process.env.REACT_APP_TYPE,
  backendUrl:
    // process.env.REACT_APP_BACKEND_URL ||
    "https://cavapi.timeguard.ng",
  routes,
  encryptionKey: "challengemeoften2024",
  frontendUrl: "http://csp.timeguard.ng",
};

environment.staging = {
  authProps: ["cav/token", "cav/user", "cav/app-type"],
  appType: process.env.REACT_APP_TYPE,
  backendUrl: "https://cavapi.timeguard.ng/",
  routes,
  encryptionKey: "challengemeoften2024",
  frontendUrl: "http://csp.timeguard.ng",
};

environment.production = {
  authProps: ["cav/token", "cav/user", "cav/app-type"],
  appType: process.env.REACT_APP_TYPE,
  backendUrl: "https://cavapi.timeguard.ng",
  routes,
  encryptionKey: "challengemeoften2024",
  frontendUrl: "http://csp.timeguard.ng",
};
// DO NOT REMOVE
console.log(process.env.REACT_APP_ENV);
console.log(process.env.REACT_APP_BACKEND_URL);
console.log(process.env.REACT_APP_TYPE);
console.log(environment);

export default environment[process.env.REACT_APP_ENV || "development"];
