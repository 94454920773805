/* eslint-disable react-hooks/exhaustive-deps */
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { LoadingButton } from "@mui/lab";
import { Box, FormControl, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SelectInput from "../../components/forms/SelectInput";
import { fetchBranches, getAuthData } from "../../features/auth/authSlice";
import {
  createAdmin,
  createEmployee,
  getEmployeesData,
  setPagination,
  setPayload,
  updateUser,
} from "../../features/employee/employee";
import HomeLayout from "../../layouts/Home";
import { AppType, Roles } from "../../utils/helper";
import { vcreateEmployee } from "../../utils/validators";

const CreateEmployee = () => {
  const navigate = useNavigate(),
    { state } = useLocation(),
    [checked, setChecked] = useState({ cav: false, csp: false }),
    dispatch = useDispatch(),
    { model, pagination, modalLoading } = useSelector(getEmployeesData),
    { branches, loading: branchLoading, appType } = useSelector(getAuthData),
    emptyModel = {
      firstName: "",
      lastName: "",
      email: "",
      employeeId: "",
      branchId: "",
      role: "",
      permittedApps: [appType === AppType.cav ? "CAV" : "CSP"],
    },
    {
      setValues,
      values,
      handleBlur,
      handleChange,
      handleFocus,
      handleSubmit,
      errors,
      touched,
      resetForm,
    } = useFormik({
      validationSchema: vcreateEmployee,
      initialValues: model,
      onSubmit: async ({ role, permittedApps, ...payload }) => {
        let action,
          values = { ...payload };
        permittedApps = [appType === AppType.cav ? "CAV" : "CSP"];
        if (role?.toLowerCase() !== Roles.admin && permittedApps?.length > 0) {
          action = createEmployee;
          values.permittedApps = permittedApps;

          if (state) {
            action = updateUser;
            values.userId = state.id;
          }
        } else if (role.toLowerCase() === Roles.admin) {
          action = createAdmin;
          values = payload;
          values.role = Roles.admin;
          if (state) {
            action = updateUser;
            values.userId = state.id;
          }
        }

        const res = await dispatch(action(values)).unwrap();
        if (res?.success) {
          navigateBack();
        }
      },
    });

  useEffect(() => {
    let cb = () => {};
    if (pagination?.search) {
      cb = setTimeout(
        () => (async () => await dispatch(fetchBranches(pagination.search)))(),
        700
      );
    } else cb = (async () => await dispatch(fetchBranches()))();

    return () => {
      clearTimeout(cb);
    };
  }, [pagination.search]);

  const handleSearch = ({ target: { value } }) =>
    dispatch(setPagination({ search: value }));

  useEffect(() => {
    if (state !== null) {
      if (state?.permittedApps?.length === 2)
        setChecked({ cav: true, csp: true });
      else {
        setChecked({
          ...checked,
          [state?.permittedApps?.[0]?.toLowerCase()]: true,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (checked.cav && checked.csp) {
      setValues({ ...values, permittedApps: ["CAV", "CSP"] });
    } else if (checked.cav) {
      setValues({ ...values, permittedApps: ["CAV"] });
    } else if (checked.csp) {
      setValues({ ...values, permittedApps: ["CSP"] });
    } else {
      setValues({ ...values, permittedApps: [] });
    }
  }, [checked.cav, checked.csp]);

  // const handleProfiling = ({ target: { name, checked: c } }) => {
  //   setChecked((state) => ({ ...state, [name]: c }));
  // };

  function navigateBack() {
    setValues(emptyModel);
    dispatch(setPayload(emptyModel));
    resetForm();
    navigate(-1);
  }

  return (
    <HomeLayout {...{ navigation: false, handleSearch: false }}>
      <div className="-mt-[5%]">
        <div className="">
          <Box className="text-[var(--c-dark-1)] flex mb-5">
            <span
              className="flex cursor-pointer items-center hover:bg-[#eee] rounded-md px-1"
              onClick={navigateBack}
            >
              <ArrowBackIosNewIcon className="mr-2" fontSize="small" />
              <Typography className="text-[400] text-[16px] ">Back</Typography>
            </span>
          </Box>
          <Typography variant="h6" className="text-2xl">
            Registered Employee
          </Typography>

          <div
            className="py-10 my-5 px-[5%] bg-[#EAEAEA4D] mx-[10%]"
            style={{ borderTopLeftRadius: "100" }}
          >
            <Typography className="text-[400] text-2xl mt-8 ml-3 text-[#040316]">
              {state ? "Edit" : "New"} Employee
            </Typography>
            <Box
              className="rounded-lg lg:px-[4em] px-[1em]"
              sx={{
                className: "w-[100%] lg:w-[80%]",
                py: "2em",
              }}
              noValidate
              component="form"
              onSubmit={handleSubmit}
            >
              <Box className="grid lg:grid-cols-2 gap-4   m-auto">
                <FormControl className="w-full border-none lg:mt-5">
                  <label
                    htmlFor="firstName"
                    className="block mb-1 text-[16px] font-medium  text-[#090914]"
                  >
                    First Name
                  </label>
                  <TextField
                    className="w-full bg-white"
                    id="firstName"
                    name="firstName"
                    value={values.firstName}
                    required
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.firstName && touched.firstName}
                    helperText={
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : ""
                    }
                  />
                </FormControl>
                <FormControl className="w-full border-none lg:mt-5">
                  <label
                    htmlFor="lastName"
                    className="block mb-1 text-[16px] font-medium  text-[#090914]"
                  >
                    Last Name
                  </label>
                  <TextField
                    className="w-full bg-white"
                    id="lastName"
                    name="lastName"
                    value={values.lastName}
                    required
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.lastName && touched.lastName}
                    helperText={
                      errors.lastName && touched.lastName ? errors.lastName : ""
                    }
                  />
                </FormControl>

                <FormControl className="w-full border-none lg:mt-5">
                  <label
                    htmlFor="employeeId"
                    className="block mb-1 text-[16px] font-medium  text-[#090914]"
                  >
                    Employee ID
                  </label>
                  <TextField
                    className="w-full bg-white"
                    id="employeeId"
                    name="employeeId"
                    value={values.employeeId}
                    required
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.employeeId && touched.employeeId}
                    helperText={
                      errors.employeeId && touched.employeeId
                        ? errors.employeeId
                        : ""
                    }
                  />
                </FormControl>

                <FormControl className="w-full border-none lg:mt-5">
                  <label
                    htmlFor="email"
                    className="block mb-1 text-[16px] font-medium  text-[#090914]"
                  >
                    Email Address
                  </label>
                  <TextField
                    className="w-full bg-white"
                    id="email"
                    name="email"
                    value={values.email}
                    required
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email && touched.email}
                    helperText={
                      errors.email && touched.email ? errors.email : ""
                    }
                  />
                </FormControl>

                <FormControl className="w-full border-none lg:mt-5">
                  <label
                    htmlFor="branchId"
                    className="block mb-1 text-[16px] font-medium  text-[#090914]"
                  >
                    State
                  </label>

                  <SelectInput
                    {...{
                      className: "w-full bg-white",
                      value: values.branchId,
                      searchValue: pagination.search,
                      id: "branchId",
                      name: "branchId",
                      handleChange,
                      handleBlur,
                      required: false,
                      handleFocus,
                      error: errors.branchId && touched.branchId,
                      helperText: errors.branchId,
                      loading: branchLoading,
                      searchLoading: pagination.search && branchLoading,
                      options: branches.map((d) => ({
                        id: d.Id,
                        value: d.Name,
                      })),
                      handleSearch,
                    }}
                  />
                </FormControl>

                <FormControl className="w-full border-none lg:mt-5">
                  <label
                    htmlFor="branchId"
                    className="block mb-1 text-[16px] font-medium  text-[#090914]"
                  >
                    Role
                  </label>

                  <SelectInput
                    {...{
                      className: "w-full bg-white",
                      value: values.role,
                      id: "role",
                      name: "role",
                      handleChange,
                      handleBlur,
                      required: false,
                      handleFocus,
                      error: errors.role && touched.role,
                      helperText: errors.role,
                      options: ["Banker", "Admin"],
                    }}
                  />
                </FormControl>

                {/* {values?.role?.toLowerCase() !== Roles.admin && (
                  <FormControl className="w-full lg:mt-5 ml-">
                    <label
                      htmlFor="permittedApps"
                      className="block mb-1 text-[16px] font-medium  text-[#090914]"
                    >
                      Profile On
                    </label>

                    <div className="flex">
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={values?.permittedApps?.find(
                              (a) => a === "cav"
                            )}
                            checked={checked.cav}
                            name="cav"
                            onChange={handleProfiling}
                          />
                        }
                        label="CAV"
                      />
                      <FormControlLabel
                        className="inline"
                        control={
                          <Checkbox
                            value={values?.permittedApps?.find(
                              (a) => a === "csp"
                            )}
                            checked={checked.csp}
                            onChange={handleProfiling}
                            name="csp"
                          />
                        }
                        label="CSP"
                      />
                    </div>

                    {touched?.permittedApps &&
                      values?.permittedApps?.length === 0 && (
                        <FormHelperText
                          fullWidth
                          sx={{ mx: 0, p: "3px 14px 0px" }}
                          className="bg-white mx-0 w-full px-2"
                          error={values?.permittedApps?.length === 0}
                        >
                          {touched?.permittedApps &&
                            values?.permittedApps?.length === 0 &&
                            (errors?.permittedApps || "Profile On is required")}
                        </FormHelperText>
                      )}
                  </FormControl>
                )} */}
              </Box>
              <Box className="flex justify-start mt-10 items-start">
                <div className="flex justify-start w-full">
                  <button
                    onClick={navigateBack}
                    className="h-[3.5em] w-full items-center rounded-md px-3  text-sm font-semibold text-dark_red bg-[#f5ebeb] hover:bg-[#ffe1e0] mr-5"
                    size="large"
                    type="button"
                  >
                    Cancel
                  </button>

                  <LoadingButton
                    style={{ width: "100%", height: "3.5em" }}
                    className="h-[2.3rem] items-center rounded-md px-3  text-sm font-semibold bg-dark_red text-white hover:bg-light_red"
                    variant="contained"
                    color="error"
                    sx={{
                      ".MuiLoadingButton-loadingIndicatorCenter": {
                        color: "var(--C_primary) !important",
                      },
                    }}
                    type="submit"
                    disabled={modalLoading}
                    loading={modalLoading}
                    fullWidth
                  >
                    {state ? "Save Changes" : "Submit"}
                  </LoadingButton>
                </div>
                <div className="w-full"></div>
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </HomeLayout>
  );
};

export default CreateEmployee;
