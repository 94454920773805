/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import HomeLayout from "../../layouts/Home";
import { Typography } from "@material-tailwind/react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TableLayout from "../../components/table/CustomTable";
import { ICopy } from "../../utils/icons.utils";
import { useDispatch, useSelector } from "react-redux";
import {
  AppType,
  Roles,
  capitalize,
  getAmount,
  getId,
  getRole,
  handleCopy,
} from "../../utils/helper";
import SelectButton from "../../components/others/SelectBtn";
import PrintButton from "../../components/others/Print";
import {
  closeModal,
  getDefaultState,
  openModal,
  setPrintMod,
} from "../../features/defaultSlice";
import { useLocation, useNavigate } from "react-router-dom";
import * as jobsAction from "../../features/jobs/jobs";
import * as vendorAction from "../../features/vendor/vendor";
import config from "../../utils/config";
import CustomModal from "../../components/modals/Index";
import MakePayment from "./MakePayment";
import { LoadingButton } from "@mui/lab";
import { getAuthData, setModal } from "../../features/auth/authSlice";
import PayVendor from "./PayVendor";
import PersonIcon from "@mui/icons-material/Person";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { Button, Tooltip } from "@mui/material";
import ConfirmationModal from "../../components/modals/confirmationModal";
import MoveJobs from "../../components/modals/MoveJobs";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import CurtainsClosedIcon from "@mui/icons-material/CurtainsClosed";
import RecyclingIcon from "@mui/icons-material/Recycling";
import VendorAssignment from "../manualReassignment/VendorAssignment";
import dayjs from "dayjs";

const AwaitingPayments = () => {
  const { fetchVendorsAwaitingPayments, getVendorsData } = vendorAction,
    { fetchData, getDownloadData, getJobsData } = jobsAction,
    { appType, ...authData } = useSelector(getAuthData),
    [grouped, setGrouped] = useState(true),
    [adminAction, setAdminAction] = useState({}),
    [showText, setShowText] = useState(false),
    [checkboxData, setCheckboxData] = useState([]);

  const tableRef = useRef();
  const dispatch = useDispatch(),
    navigate = useNavigate(),
    { pathname } = useLocation(),
    { downloadData, modalLoading, currentJob, ...jobsData } =
      useSelector(getJobsData),
    { vendors, currentData, modal, ...vendorsData } =
      useSelector(getVendorsData),
    { printMod, modal: defaultModal } = useSelector(getDefaultState);
  let action, awaitingPayment, setPagination, loading, pagination;
  if (getRole() === Roles.vendor || !grouped) {
    action = fetchData;
    loading = jobsData.loading;
    awaitingPayment = jobsData["awaitingPayment"];
    setPagination = jobsAction.setPagination;
    pagination = jobsData.pagination;
  } else if (grouped) {
    loading = vendorsData.loading;
    pagination = vendorsData.pagination;
    setPagination = vendorAction.setPagination;
    action = fetchVendorsAwaitingPayments;
    awaitingPayment = vendors;
  }

  useEffect(() => {
    !showText && setShowText(true);
    dispatch(vendorAction.clearVendorData());
    dispatch(setPagination({ page: 1, search: "" }));
    dispatch(setPrintMod({ pageTitle: "Awaiting Payment" }));
    dispatch(jobsAction.getJobsCost());
  }, []);

  useEffect(() => {
    dispatch(
      getDownloadData({
        url: pathname,
        type: appType === AppType.cs ? "corporate/" : "",
      })
    );
    let cb = () => {};
    if (pagination?.search) {
      dispatch(setPagination({ page: 1 }));
      cb = setTimeout((_) => (async () => await dispatch(action()))(), 700);
    } else cb = (async () => await dispatch(action()))();

    return () => {
      clearTimeout(cb);
    };
  }, [pagination.search, pagination.page, pagination.pageSize, grouped]);

  let data =
    getRole() !== Roles.vendor && grouped
      ? awaitingPayment?.map((d) => ({
          "Solicitor Name":
            capitalize(d?.VendorPreferredName || d?.VendorName || "") || "N/A",
          "Solicitor’s Email Address": capitalize(d?.VendorEmail) || "N/A",
          // "Solicitor’s Account Number": d?.VendorAcctNo ?? "N/A",
          "Total Jobs": d?.totalJobs ? getAmount(d?.totalJobs) : "N/A",
          _data: d,
        }))
      : awaitingPayment?.map((d) => {
          let model = {};
          if (getRole() !== Roles.vendor)
            model["Account Number"] = d?.AcctNumber || "N/A";

          model = {
            ...model,
            "Account Name": capitalize(d?.AcctName) || "N/A",
          };

          if (AppType.cs === appType) model["RC Number"] = d?.RCNumber || "N/A";
          else {
            model["Address"] = capitalize(d?.AcctAddress) || "N/A";
            model["Updated Address"] =
              capitalize(d?.UpdatedAcctAddress) || "N/A";
          }

          model = {
            ...model,
            State: capitalize(d?.State?.Name || "N/A"),
            "Solicitor's Name":
              capitalize(
                d?.AssignedVendor?.VendorPreferredName ||
                  d?.AssignedVendor?.VendorName ||
                  d?.VendorJobs?.[0]?.Vendor?.VendorPreferredName ||
                  d?.VendorJobs?.[0]?.Vendor?.VendorName
              ) || "N/A",
            ...(getRole() !== Roles.vendor && {
              "Assigned By": d?.InitiatedByUser?.firstName
                ? `${d?.InitiatedByUser?.firstName} ${d?.InitiatedByUser?.lastName}`
                : "N/A",
            }),
            "Assigned Date": d?.DateAssigned
              ? dayjs(d?.DateAssigned).format("DD/MM/YY, hh:mm A")
              : "N/A",
            "Confirmed Date": d?.DateConfirmed
              ? dayjs(d?.DateConfirmed).format("MMM DD, YYYY")
              : "N/A",
            "Verify Result":
              d?.AddedInfo?.toLowerCase() === "successful"
                ? "Yes"
                : "No" || "N/A",
            _data: d,
          };
          return model;
        });

  const dropdownData = {
    visible: true,
    type: "dropdown",
    icon: <MoreHorizIcon />,
    data: [
      {
        text: "View Jobs",
        action: async (_, state) => {
          navigate(config.routes.awaitingPayments + "/" + state.VendorID, {
            state,
          });
        },
      },
      {
        text: "Mark As Paid",
        action: async (_, state) => {
          dispatch(jobsAction.setCurrentJob(state));
          dispatch(openModal("close"));
        },
      },
    ],
  };

  const handleSearch = ({ target: { value } }) => {
    dispatch(setPagination({ ...pagination, search: value }));
  };

  const checkboxAction = {
    setValues: (data) => setCheckboxData(data),
    values: checkboxData,
  };

  const refresh = async () => {
    dispatch(vendorAction.closeModal("open"));
    dispatch(closeModal("close"));
    dispatch(setModal({ open: false }));
    setCheckboxData([]);
    await dispatch(action());
  };

  const buttonMeta = [
    {
      title: "Reassign",
      Icon: RecyclingIcon,
      action: (_) => dispatch(setModal({ open: true })),
    },
    {
      title: "Close",
      Icon: CurtainsClosedIcon,
      action: jobsAction.closeJob,
      loading: modal.loader.closedJobs,
    },
  ];

  const initBulkAction = (meta, idx) => {
    if (idx) {
      setAdminAction(meta);
      dispatch(setModal({ close: true }));
    } else meta.action();
  };

  return (
    <HomeLayout
      {...{
        handleSearch,
        value: pagination.search,
        placeholder:
          [Roles.bank, Roles.admin].includes(getRole()) && grouped
            ? "Search by solicitor's name..."
            : "Search by account number...",
      }}
    >
      <div className="flex items-end flex-wrap gap-2 justify-between">
        <div className="">
          <Typography className="text-[24px] font-[500]">
            Awaiting Payment
          </Typography>
          <Typography className="text-[18px] font-[400]">
            Completed jobs awaiting payment from bank
          </Typography>
        </div>
        <div className="flex flex-wrap md:justify-end gap-4 md:mt-0 mt-3">
          <button
            onClick={(_) => handleCopy(data)}
            className="h-[2.3rem] flex items-center rounded-md px-3 border-2 border-[#E6E6E6] text-sm font-semibold text-dark_red "
          >
            <img src={ICopy} alt="ICopy" />
            Copy
          </button>
          <PrintButton
            {...{ componentRef: tableRef, documentTitle: printMod.pageTitle }}
          />
          <SelectButton
            btTextClass="bg-dark_red text-white hover:bg-light_red"
            options={downloadData}
            title="Download"
          />
          {getRole() !== Roles.vendor ? (
            <LoadingButton
              onClick={async (_) => {
                await dispatch(jobsAction.fetchSummary()).unwrap();
                dispatch(vendorAction.openModal("open"));
              }}
              loading={modalLoading}
              variant="outlined"
              color="error"
              disabled={checkboxData.length > 0 && grouped ? true : false}
              type="button"
              sx={{ textTransform: "capitalize" }}
              className="inline-flex h-[2.3rem] items-center rounded-md px-3 text-sm font-semibold bg-dark_red text-white hover:bg-light_red"
            >
              Mark All Jobs As Paid
            </LoadingButton>
          ) : null}
        </div>
      </div>
      <div className="mt-5">
        {getRole() !== Roles.vendor && (
          <div className="flex">
            <Tooltip className="py-0 h-10" title="List Jobs" placement="top">
              <button
                onClick={(_) => {
                  setCheckboxData([]);
                  setGrouped(!grouped);
                }}
                className={` px-2 py-0 hover:border-[#D70900] py- border ${
                  !grouped ? "bg-[#D70900] text-white" : "text-[#D70900]"
                }`}
              >
                <PersonIcon />
              </button>
            </Tooltip>
            <Tooltip className="py-0 h-10" title="Group Jobs" placement="top">
              <button
                onClick={(_) => {
                  setCheckboxData([]);
                  setGrouped(!grouped);
                }}
                className={`px-2 py-1 hover:border-[#D70900] border ${
                  grouped ? "bg-[#D70900] text-white" : "text-[#D70900]"
                }`}
              >
                <PeopleAltIcon />
              </button>
            </Tooltip>

            {!grouped && (
              <div className="flex justify-between flex-wrap gap-y-5 w-full">
                <div className="flex flex-wrap gap-y-5">
                  {[Roles.admin, Roles.superAdmin].includes(getRole()) &&
                    buttonMeta.map((meta, i) => (
                      <Tooltip
                        key={i}
                        className="py-0 h-10"
                        title={meta.title + " Jobs"}
                        placement="top"
                      >
                        <span>
                          <button
                            onClick={(_) => initBulkAction(meta, i)}
                            color="error"
                            disabled={checkboxData.length ? false : true}
                            className="px-2 py-[6px] hover:border-[#D70900] focus:border-[#D70900] disabled:border-gray-200 disabled:text-[grey] border text-[#D70900]"
                          >
                            <meta.Icon />{" "}
                            {showText && (
                              <span className="text-[15px] ml-1">
                                {" "}
                                {meta.title}{" "}
                              </span>
                            )}
                          </button>
                        </span>
                      </Tooltip>
                    ))}

                  <div className="pt-[1px] md:ml-5 ">
                    <Button
                      onClick={(_) => {
                        dispatch(openModal("delete"));
                      }}
                      disabled={checkboxData.length > 0 ? false : true}
                      variant="outlined"
                      color="error"
                      type="button"
                      style={{ padding: "5.5px 10px" }}
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: "0px",
                      }}
                    >
                      Mark Jobs As Paid
                    </Button>
                  </div>
                </div>
                {[Roles.admin, Roles.superAdmin].includes(getRole()) && (
                  <button
                    onClick={(_) => {
                      dispatch(jobsAction.openModal("close"));
                    }}
                    disabled={checkboxData.length ? false : true}
                    className={`px-2 hover:border-[#D70900] shadow disabled:text-grey disabled:bg-transparent disabled:border-grey flex items-center rounded border hover:bg-[#D70900] text-[#D70900] hover:text-white`}
                  >
                    <DriveFileMoveIcon />{" "}
                    <span className="text-[15px] ml-1">Move Jobs</span>
                  </button>
                )}
              </div>
            )}

            <div className="pt-[1px] ml-5">
              {grouped && (
                <Button
                  onClick={(_) => {
                    dispatch(openModal("delete"));
                  }}
                  disabled={checkboxData.length > 0 ? false : true}
                  variant="outlined"
                  color="error"
                  type="button"
                  style={{ padding: "5.5px 10px" }}
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: "0px",
                  }}
                >
                  Mark Jobs As Paid
                </Button>
              )}
            </div>
          </div>
        )}
        <TableLayout
          {...{
            printMod: { ...printMod, ref: tableRef },
            pagination,
            setPagination,
            isLoading: loading,
            data,
            checkboxAction:
              [Roles.bank, Roles.admin, Roles.superAdmin].includes(getRole()) &&
              checkboxAction,
            action: getRole() !== Roles.vendor && grouped && dropdownData,
          }}
        />
      </div>

      <CustomModal
        {...{
          title: "Bulk payment",
          open: modal.open,
          close: (_) => dispatch(vendorAction.closeModal("open")),
        }}
      >
        <MakePayment
          {...{
            data: currentJob,
            loading: jobsData.modal.loader.confirm,
            action: async (_) => {
              const res = await dispatch(
                jobsAction.makePayment({ isBulk: true })
              ).unwrap();
              if (res?.success) {
                await refresh();
              }
            },
          }}
        />
      </CustomModal>

      <CustomModal
        {...{
          title: "Confirm Payment",
          open: defaultModal.close,
          close: (_) => dispatch(closeModal("close")),
        }}
      >
        <PayVendor
          {...{
            data: currentJob,
            refresh,
            closeModal: (_) => dispatch(closeModal("close")),
          }}
        />
      </CustomModal>

      <ConfirmationModal
        {...{
          open: defaultModal.delete,
          close: (_) => dispatch(closeModal("delete")),
          refresh: (_) => dispatch(action()),
          data: {
            medium: grouped ? "VENDOR" : "ACCOUNT",
            ...(grouped
              ? { vendorIds: checkboxData.map((data) => data?.VendorID) }
              : { accountIds: checkboxData.map((data) => data?.AcctRefID) }),
          },
          btnTexts: ["Cancel", "Confirm"],
          title: "Confirm Payment",
          loading: jobsData.modal.loader.confirm,
          subtitle: `Are you sure you want to mark ${
            checkboxData.length > 1 ? "these" : "this"
          } ${
            checkboxData.length > 1
              ? "solicitors jobs as paid"
              : "solicitor's jobs as paid"
          }?`,
          action: jobsAction.makePayment,
        }}
      />

      {[Roles.admin, Roles.superAdmin].includes(getRole()) && (
        <>
          <CustomModal
            {...{
              title: `Reassign ${checkboxData.length} ${
                checkboxData.length > 1 ? "Jobs" : "Job"
              } Manually`,
              close: (_) => dispatch(setModal({ open: false })),
              open: authData.modal.open,
            }}
          >
            <VendorAssignment
              {...{
                closeModal: (_) => dispatch(setModal({ open: false })),
                data: checkboxData,
                refresh,
              }}
            />
          </CustomModal>
          <CustomModal
            {...{
              title: `Move ${checkboxData.length} ${
                checkboxData.length > 1 ? "Jobs" : "Job"
              }`,
              open: jobsData.modal.close,
              close: (_) => dispatch(jobsAction.closeModal("close")),
            }}
          >
            <MoveJobs
              {...{
                closeModal: (_) => dispatch(jobsAction.closeModal("close")),
                data: checkboxData,
                refresh,
              }}
            />
          </CustomModal>

          <ConfirmationModal
            {...{
              open: authData.modal.close,
              close: (_) => dispatch(setModal({ close: false })),
              refresh,
              data:
                adminAction.title === "Close"
                  ? checkboxData?.map((d) => d?.AcctRefID)
                  : {
                      bankUserId: getId().admin,
                      accountIds: checkboxData?.map((d) => d?.AcctRefID),
                    },
              btnTexts: ["Cancel", adminAction.title],
              title: adminAction.title + " Jobs",
              loading: jobsData.modal.loader.closedJobs,
              subtitle: `Are you sure you want to ${adminAction?.title?.toLowerCase()} ${
                checkboxData.length > 1 ? "these" : "this"
              } ${checkboxData.length > 1 ? checkboxData.length : ""} ${
                checkboxData.length > 1 ? "jobs" : "job"
              }?`,
              action: adminAction.action,
            }}
          />
        </>
      )}
    </HomeLayout>
  );
};

export default AwaitingPayments;
